<template>
  <div class="vg_wrapper">
    <el-card>
      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getModrsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="订单编号:">
                <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写订单编号"></el-input>
              </el-form-item>
            </el-col>
              <el-col :md="8">
                <el-form-item label="采购方:">
                  <el-select v-model="searchForm.cptt_id" filterable placeholder="请选择采购方" clearable size="small">
                    <el-option
                    v-for="item in cpttFctrGroupOpt"
                    :key="item.cptt_id"
                    :label="item.cptt_name"
                    :value="item.cptt_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="销售方:">
                <el-select v-model="searchForm.modr_cptt_id" filterable placeholder="请选择销售方" clearable size="small">
                  <el-option
                    v-for="item in cpttGroupOpt"
                    :key="item.cptt_id"
                    :label="item.cptt_name"
                    :value="item.cptt_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <el-select v-model="searchForm.status" filterable placeholder="请选择单据状态" clearable size="small">
                  <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="交货时间:">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="14">
              <el-form-item label="录入时间:">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getModrsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border v-loading="loadingFlag">
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="订单编号" prop="modr_no" />
            <el-table-column label="采购方" prop="cptt_name" />
            <el-table-column label="销售方" prop="modr_cptt_name" />
            <el-table-column label="交货时间" prop="modr_date" :formatter="formatDate1" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {modrAPI} from "@api/modules/modr"
import pubPagination from "@/components/common/pubPagination";
import {cpttAPI} from "@api/modules/comptitle";
import {acctAPI} from "@api/modules/acct";
export default {
  name: "ModrList",
  components: {
    pubPagination,
  },
  data(){
    return{
      searchForm:{
        modr_cptt_id:null,
        cptt_id:null,
        modr_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      btn:{},
      cpttFctrGroupOpt:[],
      cpttGroupOpt:[],
      loadingFlag:true,
      statusList:[{
        id:0,
        label:'草拟'
      },{
        id:1,
        label:'在批'
      },{
        id:2,
        label:'生效'
      },]
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/modr_add' || from.path === '/modr_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
      this.getModrsList()
      this.getCpttPurchaser()
      this.getCpttId()
    },
    //获取物料采购列表
    getModrsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1)
      get(modrAPI.getModrs,
          {
            modr_no: this.searchForm.modr_no,
            modr_cptt_id: this.searchForm.modr_cptt_id,
            cptt_id: this.searchForm.cptt_id,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            start_time2: timeNewVal1.startTime,
            end_time2: timeNewVal1.endTime,
            status:this.searchForm.status,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取采购方
    getCpttPurchaser(){
      get(cpttAPI.get_my_cptts_v1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttFctrGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取合作单位id
    getCpttId(flag){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 刷新
    buttonRefresh(){
      this.$refs.pubPagination.currentPage = 1
      this.searchForm = {
        modr_cptt_id:null,
        cptt_id:null,
        modr_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      };
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    formatDate1(row) {
      if(row.modr_date){
        return this.helper.toTimeDay(row.modr_date)
      }else{
        return '暂无'
      }
      
    },
    // 查询方法
    getModrsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 新增
    addRow(){
      const permId = this.$route.query.perm_id
      this.jump(`/modr_add?perm_id=${permId}`)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      }).catch(()=>{})
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.modr_id)
      })
      post(modrAPI.deleteModrByIds,{'modr_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              if(ids.length === this.tableData.length){
                if(this.currentPage > 1){
                  this.currentPage = this.currentPage -1
                  this.$refs.pubPagination.currentPage = this.currentPage
                }
              }
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/modr_edit',{perm_id:permId,form_id:row.modr_id})
    }
  }
}
</script>

<style scoped>

</style>